.App{
    position: relative;
    overflow-x: clip;
    
}

.App>:nth-child(1){
    background-color: var(--black);
    position: relative;
}

.white-gradient{
    position: absolute;
    width: 20rem;
    height: 20rem;
    background: rgba(255, 255, 255, 0.522);
    filter: blur(200px);
}