.inner-container{
    gap: 1.5rem;
    background: #4161df;
    padding: 2em;

border-radius: 10px;
border: 6px solid #5d77d6;
text-align: center;
}
.inner-container .primaryText{
    color: white;
    font-weight: 600;
}
.inner-container .secondaryText{
    color: rgba(255, 255, 255,0.587);
}
.inner-container .button{
   background-color: #5a73d7;
   border: 2px solid white;
   box-shadow: var(--shadow);
}