.hero-wrappper{
    color: white;
    position: relative;
    
   
}

.orange-circle{
    height: 4rem;
    width: 4rem;
    background: var(--orange-gradient);
    border-radius: 990px;
    position: absolute;
    right: 28%;
    top: -10%;
    z-index: -1;
}

.search-bar{
    background-color: white;
    border-radius: 4px;
    border: 3px solid rgba(120, 120, 120,0.372);
    padding: 0.5rem 1rem;
    width: 100%;
    justify-content: space-between;
}

.search-bar>input{
    border: none;
    outline: none;
  
}

.stats{
    width: 100%;
    justify-content: space-between;
}

.stat>:nth-child(1){
   font-size: 2rem;
}

.stat>:nth-child(1)>:last-child{
   color: orange;
}


.hero-title{
    position: relative;
    z-index: 1;
}

.hero-title>h1{
    font-weight: 600;
    font-size: 3.8rem;
    line-height: 4rem;
}


.hero-container{
    justify-content: space-around;
    align-items: flex-end;

}
.image-conatiner{
    height: 35rem;
    width: 30rem;
    overflow: hidden;
    border-radius: 15rem 15rem 0 0;
    border: 8px solid rgba(255, 255 , 255 , 0.12);
}

@media (max-width: 640px) {
    .hero-container{
        margin-top: 2em;
    }
    .hero-title>h1{
        font-size: 2.5rem;
        line-height: 3rem;
    }
    .image-conatiner{
        width: 95%;
        height: 25rem;
    }
    .stats{
        justify-content: center;
        gap: 1.5em;
    }
    .hero-right{
       width: 100%;
    }
    .stats:nth-child(1){
        font-size: 1.5em;
    }
    .stats:nth-child(2){
        font-size: 0.8em;
    }
}